// Factorio
(function (w, d, $) {

    /* jQuery plugins */
    if( typeof $.cookie === 'undefined' ) $.constructor.prototype.cookie = function (name, value, options) {
        if (typeof value != 'undefined') {
            options = options || {};
            if (value === null) {
                value = '';
                options.expires = -1
            }
            var expires = '';
            if (options.expires && (typeof options.expires == 'number' || options.expires.toUTCString)) {
                var date;
                if (typeof options.expires == 'number') {
                    date = new Date();
                    date.setTime(date.getTime() + (options.expires * 24 * 60 * 60 * 1000))
                } else {
                    date = options.expires
                }
                expires = '; expires=' + date.toUTCString()
            }
            var path = options.path ? '; path=' + (options.path) : '';
            var domain = options.domain ? '; domain=' + (options.domain) : '';
            var secure = options.secure ? '; secure' : '';
            d.cookie = [name, '=', encodeURIComponent(value), expires, path, domain, secure].join('')
        } else {
            var cookieValue = null;
            if (d.cookie && d.cookie != '') {
                var cookies = d.cookie.split(';');
                for (var i = 0; i < cookies.length; i++) {
                    var cookie = cookies[i].trim();
                    if (cookie.substring(0, name.length + 1) == (name + '=')) {
                        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                        break
                    }
                }
            }
            return cookieValue
        }
    };

    if( typeof $.fn.on === 'undefined' && typeof $.fn.bind === 'function' ) $.fn.on = function(event, sel, handler){
        if(typeof sel === 'string') $(sel).bind(event, handler);
        else if(typeof sel === 'function') {
            handler = sel;
            return this.each(function() {
                $(this).bind(event, handler);
            });
        }
    };

    if( typeof $.fn.off === 'undefined' && typeof $.fn.unbind === 'function' ) $.fn.off = $.fn.unbind;
    /* jQuery plugins end */

    w._ifr_ = {
        o: null,
        x: null,
        frameId: 'ohnamama-GDPR-frame',
        link: '.trigger-faktorio',
        // buttonX: 'pariteni-iframe-X',
        buttonX: '',
        src: 'https://ohnamama.bg/resources/ohnamama_gdpr.html?t=' + (new Date).getTime(),
        regEx: '(bimg\.abv\.bg)|(ohnamama\.bg)|(ohnamama\.ni\.bg)'
    };
    
    w._ifr_.win = function () {
        var win = _ifr_.o[0];
        return win.contentWindow || win.contentDocument;
    };

    w._ifr_.openFrame = function () {
        createFrame();
        showManager();
    };

    w._ifr_.gdprCloseframe = function () {
        if (!_ifr_.length) {
            _ifr_.o.remove();
        }
        else return;
    };

    w._ifr_.cookie = function (name, value, options) {
        if (typeof value != 'undefined') {
            options = options || {};
            if (value === null) {
                value = '';
                options.expires = -1
            }
            var expires = '';
            if (options.expires && (typeof options.expires == 'number' || options.expires.toUTCString)) {
                var date;
                if (typeof options.expires == 'number') {
                    date = new Date();
                    date.setTime(date.getTime() + (options.expires * 24 * 60 * 60 * 1000))
                } else {
                    date = options.expires
                }
                expires = '; expires=' + date.toUTCString()
            }
            var path = options.path ? '; path=' + (options.path) : '';
            var domain = options.domain ? '; domain=' + (options.domain) : '';
            var secure = options.secure ? '; secure' : '';
            d.cookie = [name, '=', encodeURIComponent(value), expires, path, domain, secure].join('')
        } else {
            var cookieValue = null;
            if (d.cookie && d.cookie != '') {
                var cookies = d.cookie.split(';');
                for (var i = 0; i < cookies.length; i++) {
                    var cookie = cookies[i].trim();
                    if (cookie.substring(0, name.length + 1) == (name + '=')) {
                        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                        break
                    }
                }
            }
            return cookieValue
        }
    };

    w.addEventListener('message', receiveMessage, false);
    w.addEventListener('load', createFrame, false);

    function receiveMessage(e) {

        if (!(new RegExp(_ifr_.regEx, 'g')).test(e.origin)) return;

        switch (e.data) {
            case 'maximize-gdpr-frame':
                gdprMaximizeframe();
                break;
            case 'close-gdpr-frame':
                gdprCloseframe();
                break;
            case 'minimize-gdpr-frame':
                gdprMinimizeframe();
                break;
            case 'frame-loaded-show':
                factorInit(true);
                break;
            case 'frame-loaded-hide':
                factorInit(false);
                break;
        }
    }

    function showTool() {
        gdprMaximizeframe();
        _ifr_.win().postMessage('maximize-gdpr-tool', '*');
    }

    function showManager() {
        gdprMaximizeframe();
        _ifr_.win().postMessage('maximize-gdpr-manager', '*');
    }

    function hideTool() {
        gdprMinimizeframe();
        _ifr_.win().postMessage('minimize-gdpr-tool', '*');
    }

    function gdprMinimizeframe() {
        if (_ifr_.buttonX) _ifr_.x.css({ display: 'none' });
        _ifr_.o.css({
            left: 0,
            bottom: 50,
            width: 100,
            height: 100
        });
        if (_ifr_.link) _ifr_.o.hide();
        else _ifr_.o.show();
    }

    function gdprMaximizeframe() {
        _ifr_.o.css({
            left: 0,
            bottom: 0,
            width: '100%',
            height: '100%'
        }).show();
        if (_ifr_.buttonX) _ifr_.x.css({ display: 'block' });
    }

    function factorInit(f) {

        if (f) showTool();
        else hideTool();

        if (_ifr_.link) {
            if (typeof _ifr_.addLink === 'function') _ifr_.addLink();
            $(_ifr_.link).off('click');
            $('body').on('click', _ifr_.link, _ifr_.openFrame);
        }
    }

    function createFrame() {

        if (_ifr_.o != null) return;

        var ifrSrc = w._ifr_.src,
            iframe = $('<iframe id="' + _ifr_.frameId + '" frameborder="0" scrolling="no"  sandbox="allow-same-origin allow-scripts allow-popups allow-forms" />');

        iframe.attr('src', ifrSrc);
        iframe.css({
            position: 'fixed',
            zIndex: 99998,
            left: 0,
            bottom: 0,
            display: 'none'
        });

        $('body').append(iframe);
        _ifr_.o = $('#' + _ifr_.frameId);

        if (_ifr_.buttonX) {
            var closeX = $('<div id="' + _ifr_.buttonX + '" />');
            
            closeX.text('x');
            closeX.css({
                zIndex: 99999,
                position: 'fixed',
                display: 'none',
                top: 5,
                right: 5,
                background: '#fff',
                padding: '5px 14px 8px',
                borderRadius: 25,
                font: 'bold 24px Arial',
                color: '#898989',
                cursor: 'pointer'
            });

            $('body').append(closeX);
            _ifr_.x = $('#' + _ifr_.buttonX);
            _ifr_.x.on('click', hideTool); 
        }
        
    }

})(window, document, typeof $ === 'function'? $ : typeof jQuery === 'function'? jQuery : {});
$(function($){

  // Open reply form
  $('.comment-reply').on('click', function(e) {
    e.preventDefault();

    $(this).closest('.comment').next('.comment-form').slideToggle();

  });

  // Like article
  $('.like-article:not(.login-link)').on('click', function(e) {
    e.preventDefault();

    let unsaveClass = 'gtm-UnSaveArticle-click';
    let saveClass = 'gtm-SaveArticle-click';

    if ($(this).closest('.video-popup')) {
      unsaveClass = 'gtm-UnSaveVideo-click';
      saveClass = 'gtm-SaveVideo-click';
    }

    if ($(this).closest('.video-popup')) {
      unsaveClass = 'gtm-UnSaveGallery-click';
      saveClass = 'gtm-SaveGallery-click';
    }

    if ($(this).hasClass('liked')) {
      $(this).removeClass('liked ' + unsaveClass);
      $(this).addClass(saveClass);
    } else {
      $(this).addClass('liked ' + unsaveClass);
      $(this).removeClass(saveClass);
    }

    
  });

  // Show poll results
  $('.poll-form').on('submit', function(e) {
    e.preventDefault();

    $(this).hide();
    $('.poll-results').show();
    setTimeout(function() {
      $('.poll-results .progress-bar > div').each(function() {
        $(this).css({
          width: $(this).attr('aria-valuenow') + '%'
        });
      });
    }, 100);
  });

  // Open comments
  $('.comments-link').on('click', function(e) {
    e.preventDefault();

    $(this).toggleClass('open');
    $('.comments-wrapper').slideToggle();
  });

  // Open login popup
  $('.login-link, .not-logged textarea, .not-logged .vote').on('click', function(e) {
    e.preventDefault();

    $('.login-popup .message').text($(this).attr('data-message')); 
    $('.login-popup').fadeIn();
    $('.global-overlay').fadeIn();

    $('.not-logged textarea').prop('disabled', true);

  });

  // Open write us popup
  $('.open-write-us').on('click', function(e) {
    e.preventDefault();

    $('.write-us-popup').fadeIn();
    $('.global-overlay').fadeIn();
  });


  // New overlay toggle
  let toggled = false;

  $('.new-overlay-toggle').on('click', function(e) {
    e.preventDefault();

    $('.new-overlay-section').toggleClass('is-open');
    $('.new-overlay-section').toggleClass('gtm-NewBlock-Open-click');
    $('.new-overlay-section').toggleClass('gtm-NewBlock-Close-click');
    toggled = true;
    
    if($('.new-overlay-section').hasClass('is-open')) {
      Cookies.remove('new-overlay');
      $('.write-us').css({
        marginBottom: $('.new-overlay-section').height()
      })
    } else {
      Cookies.set('new-overlay', 'hidden', { expires: 3 })
      $('.write-us').css({
        marginBottom: 0
      })
    }

  });

  if (Cookies.get('new-overlay') === 'hidden'){
    $('.new-overlay-section').removeClass('is-open gtm-NewBlock-Close-click');
    $('.new-overlay-section').addClass('gtm-NewBlock-Open-click');

    $('.new-overlay-section').css({
      bottom: $('.new-overlay-section').height() * -1
    });
    toggled = true;
  }

  // On scroll

  setTimeout(function(){
    onScroll();
    $(document).on('scroll', function() {
      onScroll();
    });
  }, 10);

  function onScroll() {
    let position = $(window).scrollTop();

    // Toggle new overlay section
    if($('.new-overlay-section').length > 0 && !toggled && $(window).width() > 992) {
      let articleTop;
      if ($('.read-more-section .article-block').length > 3) {
        articleTop = $('.read-more-section .article-block').eq(3).offset().top;
      } else {
        articleTop = $('body').height() / 2;
      }
      

      if(position + $(window).height() > articleTop - 40 &&
        !$('.new-overlay-section').hasClass('is-open')) {

        $('.new-overlay-section').css({
          bottom: $('.new-overlay-section').height() * -1
        });
        $('.new-overlay-section').addClass('is-open gtm-NewBlock-Close-click');
        $('.new-overlay-section').removeClass('gtm-NewBlock-Open-click');
        $('.write-us').css({
          marginBottom: $('.new-overlay-section').height()
        })
      } 

      if(position + $(window).height() < articleTop - 40 &&
        $('.new-overlay-section').hasClass('is-open')) {

        $('.new-overlay-section').removeClass('is-open gtm-NewBlock-Close-click');
        $('.new-overlay-section').addClass('gtm-NewBlock-Open-click');
        $('.write-us').css({
          marginBottom: 0
        })
      }

    }

    // Fixed share buttons
    if($('.article-header .article-social-shares-wrapper').length > 0) {
      let startPosition = $('.article-header .left-content').offset().top + $('.article-header .left-content').height();
      let endPosition = $('.article-content').offset().top + $('.article-content').height();

      if(position > startPosition && 
        position + $(window).height() < endPosition &&
        !$('.article-social-shares-wrapper').hasClass('fixed')) {

        $('.article-social-shares-wrapper').addClass('fixed');
      }

      if((position < startPosition || 
        position + $(window).height() > endPosition) &&
        $('.article-social-shares-wrapper').hasClass('fixed')) {

        $('.article-social-shares-wrapper').removeClass('fixed');
      } 

      if(position + $(window).height() > endPosition) {
        $('.article-social-shares-wrapper').addClass('hidden-mobile');
      } else {
        $('.article-social-shares-wrapper').removeClass('hidden-mobile');
      }

    }

  }

  // Add branding
  if($('.add-branding').length > 0) {
    $('body').addClass('has-branding');
  }

});

$(window).on('load', function() {
  let width = $(window).width();
  let resolution = 991;

  let stickyItem = $('.stick-wrapper');
  let stickyParent = stickyItem.closest('.grid-left-column').siblings('.grid-center-top');

  if(stickyItem.length > 0) {
    
    stickColumn(width);

    $(window).on('resize.stick-column', function() {
      let newWidth = $(window).width();
      
      if(newWidth !== width) {
        stickColumn(newWidth);
        width = newWidth;
      }

    });

    function stickColumn(width) {
      
      if(width > resolution && !stickyItem.hasClass('stick-this')) {
        stickyItem.addClass('stick-this');

        setPositionSticky();
        $(window).on('scroll.stick-column', function () {
          setPositionSticky();
        });

      }
      if(width <= resolution && stickyItem.hasClass('stick-this')) {
        stickyItem.removeClass('stick-this').removeAttr('style');
        $(window).off('scroll.stick-column');
      } 
      
    }

    function setPositionSticky(position) {
      let scrollPositionTop = $(window).scrollTop();
      let scrollPositionBottom = scrollPositionTop + $(window).height();
      let stickyItemHeight = parseInt(stickyItem.height());
      let stickyParentHeight = parseInt(stickyParent.height());
      let headerHeight = $('.page-header').outerHeight();
      let socialBtnsHeight = $('.article-social-shares-wrapper').height();
      let positionBottom = `calc(100vh -  ${stickyItemHeight}px - 60px )`;

      if(stickyItemHeight < $(window).height() - headerHeight - 20) {
        $('.stick-this').css({
          top: headerHeight + 20
        });
      } else {
        if (scrollPositionBottom > stickyParentHeight + stickyParent.offset().top) {
          $('.stick-this').css({
            top: headerHeight + 20
          });
        } else if (scrollPositionTop < stickyParent.offset().top - headerHeight) {
          $('.stick-this').css({
            top: positionBottom
          });
        }
      }

      
    }

  }

  if(width < 992) {
    $('.article-header').closest('.main-grid').css({
      paddingTop: $('.breadcrumbs').height()
    });
  }
  

});

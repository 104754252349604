$(function ($) {

  $('.due-date-form').on('submit', function(e) {
    e.preventDefault();

    if($(this).valid()) {

      $('.form-wrapper').hide();
      $('.result-wrapper').addClass('open');
      
      let day = $(this).find('#day option:selected').val();
      let month = $(this).find('#month option:selected').val();
      let year = $(this).find('#year option:selected').val();
      let selectedCycle = $(this).find('#cycle option:selected').val();
      let standartCycle = 28;
      let cycleCorrection = selectedCycle - standartCycle;

      let date = new Date(year, month - 1, day);
      let oldDate = new Date(year, month - 1, day);
      let currentDate = new Date();

      // add 40 weeks
      date.setDate(date.getDate() + 280 + cycleCorrection);

      let newDay = date.getDate();
      let newMonth = $('#month').find('option').eq(date.getMonth() + 1).text();
      let newYear = date.getUTCFullYear();

      $('.due-date-result').text(`${newDay} ${newMonth} ${newYear}`)
      
      let diffTime = currentDate.getTime() - oldDate.getTime() - (cycleCorrection * 1000 * 60 * 60 * 24);

      let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 7)); 
      $('.current-week').text(diffDays);

      if(diffDays < 0 || diffDays > 42) {
        $('.current-week').parent().hide();
      } else {
        $('.current-week').parent().show();
      }

    }

  });

});

$(function($){

  if($(window).width() >= 992) {

    if ($('.gallery-slider').length > 0) {

      $('.gallery-slider').find('.banner-wrapper').remove();
      $('.gallery-slider').slick({
        slidesToShow: 1,
        infinite: true,
        fade: true,
        adaptiveHeight: true,
        prevArrow: 
        `<button type="button" class="slick-arrow slick-prev gtm-PreviousImage-click">
          <svg class="icon" width="20" height="36" viewBox="0 0 20 36">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/resources/assets/images/defs/svg/sprite.defs.svg#arrow-left" href="/resources/assets/images/defs/svg/sprite.defs.svg#arrow-left"></use>
          </svg>
        </button>`,
        nextArrow: 
        `<button type="button" class="slick-arrow slick-next gtm-NextImage-click">
          <svg class="icon" width="20" height="36" viewBox="0 0 20 36">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/resources/assets/images/defs/svg/sprite.defs.svg#arrow-right" href="/resources/assets/images/defs/svg/sprite.defs.svg#arrow-right"></use>
          </svg>
        </button>`
      });

      const sliderAdaptiveHeight = () => {
        let height = $('.slick-active').outerHeight();
        $('.slick-list').height(height);
        console.log('set height');
      }
      
      setTimeout(() => {
        sliderAdaptiveHeight();
      }, 1500)

      $('.gallery-slider').on('afterChange', function(event, slick, currentSlide, nextSlide){
        console.log('here')
        sliderAdaptiveHeight();
      });

    }

    
  }

  $('.close-gallery').on('click', function(e) { 
    if (document.referrer.indexOf(window.location.host) !== -1 && document.referrer !== window.location.href) { 
      e.preventDefault(); 
      history.back(); 
    } 
  });

});

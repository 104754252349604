$(function($){

  $('form').each(function() {
    $(this).validate({
      highlight: function(e) {
        $(e).parent().addClass('invalid');
      },
      unhighlight: function(e) {
        $(e).parent().removeClass('invalid');
      }
    });
  }); 

  $('.family-form').on('submit', function(e) {
    // e.preventDefault();

    if($('.date-time-wrapper').hasClass('invalid')) {
      e.preventDefault();
    }

    if($('#trying').is(':checked') || checkDateTime() > 0) {
      $('.atleast-one').removeClass('invalid');
      $('.atleast-one').find('p.error').remove();

      if($(this).valid()) {
        $(this).off('submit').submit();
      }

    } else {
      e.preventDefault();
      
      $('.atleast-one').find('p.error').remove();
      $('.atleast-one').addClass('invalid');
      $('<p class="error">Моля, изберете поне едно от полетата</p>').prependTo($('.atleast-one'));
    }

  });

  $('.register-form').on('submit', function(e) {
    e.preventDefault();

    if($('#trying').is(':checked') || checkDateTime() > 0) {
      $('.atleast-one').removeClass('invalid');
      $('.atleast-one').find('p.error').remove();

      if($(this).valid()) {
        $(this).off('submit').submit();
      }

    } else {
      $('.atleast-one').find('p.error').remove();
      $('.atleast-one').addClass('invalid');
      $('<p class="error">Моля, изберете поне едно от полетата</p>').prependTo($('.atleast-one'));
    }

  });

  $('#trying').on('change', function() {
    
    if($(this).is(':checked')) {
      $('.atleast-one').removeClass('invalid');
      $('.atleast-one').find('p.error').remove();
    } 

    if(!$('#trying').is(':checked') && checkDateTime() === 0) {
      $('.atleast-one').addClass('invalid');
      $('<p class="error">Моля, изберете поне едно от полетата</p>').prependTo($('.atleast-one'));
      $('.date-time-wrapper').removeClass('invalid');
      $('.date-time-wrapper').find('p.error').remove();
    }

  });

  $('select').each(function() {
    checkSelected($(this));
  });

  $('form').on('change', 'select', function() {
    checkSelected($(this));
  });

  function checkSelected(select) {
    if(select.find('option:not(:first-child)').is(':selected')) {
      select.addClass('selected');
    } else {
      select.removeClass('selected');
    }
  }

  $('form').on('change', '.date-time-wrapper select', function() {
    let selected = 0;
    let selects = 0;
    let parent = $(this).closest('.date-time-wrapper');

    checkSelected($(this));

    parent.find('select').each(function() {
      selects++;
      if($(this).find('option:not(:first-child)').is(':selected')) {
        selected++;
      }
    });

    if (selected < selects && selected > 0) {
      $('.atleast-one').removeClass('invalid');
      $('.atleast-one').find('p.error').remove();
      parent.addClass('invalid');

      if(parent.find('p.error').length === 0) {
        $('<p class="error">Моля, попълнете датата</p>').prependTo(parent);
      }

    } else if (selected === selects) {
      $('.date-time-wrapper').removeClass('invalid');
      parent.find('p.error').hide();
    } else if (!$('#trying').is(':checked') && checkDateTime() === 0) {
      $('.date-time-wrapper').removeClass('invalid');
      $('.date-time-wrapper').find('p.error').remove();
      $('.atleast-one').addClass('invalid');
      $('<p class="error">Моля, изберете поне едно от полетата</p>').prependTo($('.atleast-one'));
    } else {
      $('.date-time-wrapper').removeClass('invalid');
      $('.date-time-wrapper').find('p.error').remove();
    }

  });

  function checkDateTime() {
    let checkedDate = 0;

    $('form .date-time-wrapper').each(function() {
      let selected = 0;
      let selects = 0;

      $(this).find('select').each(function() {
        selects++;
        if($(this).find('option:not(:first-child)').is(':selected')) {
          selected++;
        }
        
      });

      if(selected === selects) {
        checkedDate++;
      }

    });

    return checkedDate;

  }
  
  // Move placeholder
  function checkFilled(el) {
    if (el.val() && el.attr('type') !== 'hidden') {
      el.addClass('filled');
    } else {
      el.removeClass('filled');
    }
  }


  $('.form-group input, .form-group textarea').each(function() {
    checkFilled($(this));
  });

  $('.form-group input, .form-group textarea').on('blur', function() {
    checkFilled($(this));
  });

  $('.form-group input, .form-group textarea').on('change', function() {
    checkFilled($(this));
  });

  // Show password 
  $('.see-password').on('click', function(e) {
    e.preventDefault();
    if($(this).siblings('input').attr('type') === 'password') {
      $(this).siblings('input').attr('type', 'text');
    } else {
      $(this).siblings('input').attr('type', 'password');
    }
    
  });

  // Add another child
  function addInput(button, inputType, maxInputs) {

    if ($('form').find('.' + inputType + '-date').length > maxInputs) {
      return;
    }

    if(button.closest('div').hasClass('btns-wrapper')) {
      let parent = button.closest('.btns-wrapper');
      $('.' + inputType + '-date.hidden').clone().removeClass('hidden').addClass('date-time-wrapper').insertAfter(parent);
      $('<a href="" class="remove-group gtm-Remove-click">Премахни</a>').insertAfter($('form').find('.' + inputType + '-date').first()); 
    } else {
      $('.' + inputType + '-date.hidden').clone().removeClass('hidden').addClass('date-time-wrapper').insertBefore(button);
      $('<a href="" class="remove-group gtm-Remove-click">Премахни</a>').insertAfter($('form').find('.' + inputType + '-date').last()); 
    }

    if ($('form').find('.' + inputType + '-date').length > maxInputs) {
      button.addClass('disabled');
    } else {
      button.removeClass('disabled');
    }
  }

  $('.btn-add-child').on('click', function(e) {
    e.preventDefault();
    
    addInput($(this), 'child', 4)
  }); 

  $('.btn-add-pregnancy').on('click', function(e) {
    e.preventDefault();

    addInput($(this), 'pregnancy', 0)
  });

  // Change password
  $('.change-password-wrapper.open').find('input').each(function() {
    $(this).prop('disabled', false); 
  });

  $('.change-password').on('click', function(e) {
    e.preventDefault();

    $('.change-password-wrapper').find('input').each(function() {
      $(this).prop('disabled', false);
    });

    $('.change-password-wrapper').addClass('open');
  });

  $('.cancel-password-change').on('click', function(e) {
    e.preventDefault();

    $('.change-password-wrapper').find('input').each(function() {
      $(this).prop('disabled', true);
    });

    $(this).closest('.change-password-wrapper').removeClass('open');
  });

  // Remove group
  $('body').on('click', '.remove-group', function(e) {
    e.preventDefault();

    $(this).prev('.date-time-wrapper').remove();
    $(this).remove();

    $('.btn-add-child').removeClass('disabled');
    $('.btn-add-pregnancy').removeClass('disabled');

  });

  if ($('.changed-popup').is(':visible')) {
    setTimeout(function() {
      $('.changed-popup .close-popup').click();
    }, 2000);
  }

});


$(function($){

  // Close popup
  $('.close-video').on('click', function(e) {
    e.preventDefault();

    $('body').removeClass('overflow-hidden');
    $('.popup-video iframe').attr('src', '');
    $(this).closest('.video-popup').fadeOut();

  });

  $('.open-video').on('click', function(e) {
    e.preventDefault();

    $('body').addClass('overflow-hidden');

    let parent = $(this).closest('.video-group');
    let videoCategory = parent.find('.category').text();
    let videoCategoryHref = parent.find('.category').attr('href');
    let videoTitle = parent.find('.title a').text();
    let videoSrc =  parent.attr('data-video-src');
    let articleLink =  parent.attr('data-article-link');
    let videoPopup = $('.video-popup');
    let liked = parent.attr('data-liked');
    let articleId = parent.attr('data-article-id');

    videoPopup.fadeIn();
    videoPopup.find('.video-title').text(videoTitle);
    videoPopup.find('.popup-video iframe').attr('src', videoSrc);
    videoPopup.find('.btn').attr('href', articleLink);
    videoPopup.find('.category').text(videoCategory);
    videoPopup.find('.category').attr('href', videoCategoryHref);
    videoPopup.find('.like-article').removeClass('liked').addClass(liked);

    if(liked === "liked") {
      videoPopup.find('.like-article').addClass(' gtm-UnSaveVideo-click')
      videoPopup.find('.like-article').removeClass(' gtm-SaveVideo-click')
    } else {
      videoPopup.find('.like-article').removeClass(' gtm-UnSaveVideo-click')
      videoPopup.find('.like-article').addClass(' gtm-SaveVideo-click')
    }

    videoPopup.find('.like-article').attr('id', articleId);
    $('.facebook-share.with-count').html('<iframe src="https://www.facebook.com/plugins/share_button.php?locale=bg_BG&href=' + articleLink + '&layout=button_count&size=large&width=165&height=28&appId" width="165" height="28" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe>');
    $('.facebook-share.no-count').attr('href', 'http://www.facebook.com/sharer/sharer.php?u=' + articleLink + '?utm_source=ohnamama&utm_medium=facebook&utm_campaign=shareBlock');
    $('.messenger-share').attr('href', 'fb-messenger://share?link=' + articleLink + '?utm_source=ohnamama&utm_medium=fbmsg&utm_campaign=shareBlock');
    $('.viber-share').attr('href', 'https://app.adjust.com/u783g1_kw9yml?deep_link=viber://forward?text=' + articleLink + '?utm_source=ohnamama&utm_medium=viber&utm_campaign=shareBlock&fallback=https%3A%2F%2Fviber.com');

    let nextVideo;
    let nextVideoTitle;
    let nextVideoThumb;

    if (parent.next('.video-group').length > 0) {
      nextVideo = parent.next('.video-group');
      nextVideoTitle = nextVideo.find('.title a').text();
      nextVideoThumb = nextVideo.find('.img-wrapper img').attr('src');

      videoPopup.find('.video-next').show()
      videoPopup.find('.next-video-title').html(nextVideoTitle);
      videoPopup.find('.next-video-img img').attr('src', nextVideoThumb);

      $('.video-next').on('click', function(e) {
        e.preventDefault();

        nextVideo.find('.open-video').eq(0).trigger('click');
      });

    } else {
      videoPopup.find('.video-next').hide();
      $('.video-next').off('click');
    }

  });

  

});



$(function ($) {

  let dictionary = $('.dictionary-section');

  let letterFilter = dictionary.siblings('.letter-filter');

  letterFilter.find('.progress-circle').addClass('disabled');

  let currentLetters = [];

  dictionary.find('.name').each(function() {
    let letter = $(this).text()[0].toUpperCase();
    currentLetters.push(letter);
  });

  currentLetters = currentLetters.filter(function(elem, index, self) {
    return index === self.indexOf(elem);
  });

  letterFilter.find('.number').each(function() {
    let parent = $(this).closest('.progress-circle');
    let letter = $(this).text()[0];

    for(let i = 0; i < currentLetters.length; i++) {

      if (letter === currentLetters[i]) {
        parent.removeClass('disabled');
      }
    }

  });

  letterFilter.find('.progress-circle').on('click', function(e) {
    e.preventDefault();

    let letter = $(this).find('.number').text();

    if($(this).hasClass('disabled')) {
      return;
    }

    $(this).toggleClass('active');
    $(this).siblings('.progress-circle').removeClass('active');

    if($(this).hasClass('active')) {
      dictionary.find('.name-container').hide();
      dictionary.find('h2.heavy-title').text('Термини с буквата "' + letter + '"')

      dictionary.find('.name-container').each(function() {
        let currentLetter = $(this).find('.name').text()[0].toUpperCase();

        if(currentLetter === letter) {
          $(this).show();
        }

      });
    } else {
      dictionary.find('.name-container').show();
      dictionary.find('h2.heavy-title').text('Всички термини');
    }

  })

});

$(function($){

  // Menu accordion
  if ($('.accordion-item').hasClass('is-shown')) {
    $('.accordion-item.is-shown').removeClass('is-shown').addClass('is-open');
    $('.accordion-item.is-open').find('.accordion-content').show();
  }

  $('body').on('click', '.accordion-link, .category-link, .accordion-one-link', function(e) {
    e.preventDefault();

    let item = $(this).closest('.accordion-item');
    let content = $(this).siblings('.accordion-content');

    item.toggleClass('is-open').siblings('.accordion-item').removeClass('is-open is-shown');
    
    if(item.closest('.hidden-menu').length > 0) {
      item.siblings('.accordion-item').find('.accordion-content').slideUp(400);
    }

    content.slideToggle(400);
  });

});

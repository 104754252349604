$(function ($) {

  // Open filters

  $('.open-filters').on('click', function(e) {
    e.preventDefault();

    $('.filter-blocks-wrapper').slideToggle();
  });

  // Open offer popup

  $('.open-offer').on('click', function(e) {
    e.preventDefault();

    $('.offer-popup').remove();

    let imgSrc = $(this).find('.img-wrapper img').attr('src');
    let shop = $(this).find('.shop').text();
    let name = $(this).find('.name').text();
    let href = $(this).attr('href');
    let oldPrice = $(this).find('.old-price').text();
    let newPrice = $(this).find('.new-price').text();
    let description = $(this).attr('data-offer-desc'); 
    let spodsoredLogo = $(this).find('.sponsoled-block img').attr('src');

    let popupHtml = `
    <div class="popup popup-center offer-popup">
      <a href="" class="close-popup gtm-Offer-Close-click">
        <svg class="icon" width="15" height="15" viewBox="0 0 15 15">
          <use href="/resources/assets/images/defs/svg/sprite.defs.svg#close-search"></use>
        </svg>
      </a>
      <div class="popup-inner-wrapper">
        <div class="offer-block">
          <div class="img-wrapper">
            <img src="${imgSrc}" alt="${name}">
          </div>
          <div class="text-wrapper">
            <div class="sponsoredLogo-wrapper">          
              <img src="${spodsoredLogo}" alt="">
            </div>
            <span class="shop">${shop}</span>
            <span class="name">${name}</span>
            <div class="offer-bottom">
              <span class="old-price">${oldPrice}</span>
              <p class="new-price">${newPrice}</p>
              <a href="${href}" class="btn btn-small gtm-OfferBuy-Btn-click" target="_blank">Още за продукта</a>
            </div>
            <p class="description">${description}</p>
          </div>
        </div>
      </div>
    </div>
    `;

    $('body').append(popupHtml);

    if ($(window).width() > 991) {
      $('.offer-popup .description').mCustomScrollbar({
        theme: 'dark'
      }); 
    }

    $('.offer-popup').fadeIn();
    $('.global-overlay').fadeIn();
    $('body').addClass('overflow-hidden');


  })

  // offers filter
  $(".filter-blocks-wrapper input[type='checkbox']").on("click", function(){
    if(this.name == 'cat'){
      if(isInArray(parseInt(this.value), selected_cats)){
        var index = selected_cats.indexOf(parseInt(this.value));
        if (index > -1) {
          selected_cats.splice(index, 1);
        }
      }
      else{
        if(!isNaN(parseInt(this.value)))
          selected_cats.push(parseInt(this.value));
      }
    }
    else if(this.name == 'ret'){
      if(isInArray(parseInt(this.value), selected_ret)){
        var index = selected_ret.indexOf(parseInt(this.value));
        if (index > -1) {
          selected_ret.splice(index, 1);
        }
      }
      else{
        if(!isNaN(parseInt(this.value)))
          selected_ret.push(parseInt(this.value));
      }
    }
    else if(this.name == 'per'){
      if(isInArray(parseInt(this.value), selected_percent)){
        var index = selected_percent.indexOf(parseInt(this.value));
        if (index > -1) {
          selected_percent.splice(index, 1);
        }
      }
      else{
        if(!isNaN(parseInt(this.value)))
          selected_percent.push(parseInt(this.value));
      }
    }
    else if(this.name == 'kil'){
      if(isInArray(parseInt(this.value), selected_kil)){        
        var index = selected_kil.indexOf(parseInt(this.value));
        if (index > -1) {
          selected_kil.splice(index, 1);
        }
      }
      else{
        if(!isNaN(parseInt(this.value)))
          selected_kil.push(parseInt(this.value));
      }
    }
      var url = '/offers';
      has_cats = false;
      has_ret = false;
      has_per = false;

      if(selected_cats.length > 0){
        url += '?cat='+selected_cats.join();
        has_cats = true;
      }

      if(selected_ret.length > 0){
        if(has_cats)
          url += '&ret='+selected_ret.join();
        else
          url += '?ret='+selected_ret.join();
        has_ret = true;	
      }

      if(selected_percent.length > 0){
        if(has_cats || has_ret){
          url+= '&per='+selected_percent.join();
        }
        else{
          url+= '?per='+selected_percent.join();
        }
        has_per = true;
      }

      if(selected_kil.length > 0){
        if(has_cats || has_ret || has_per){
          url+= '&kil='+selected_kil.join();
        }
        else{
          url+= '?kil='+selected_kil.join();
        }
      }
      window.location = url;
  } );


  function isInArray(value, array) {
    return array.indexOf(value) > -1;
  }


  $("#current-section-more").on("click", function(){
    notINOffers = '';
    
    if(typeof notIN != 'undefined')
      notINOffers = "&notIN="+notIN.join();

    $.ajax({
          url: offers_url+'?'+window.location.search.substr(1)+'&offset='+current_offset+notINOffers,
          type: "GET",
          success: function (data) {
        data = JSON.parse(data);
        
        if(!data){
          $("#current-section-more").hide();

          return false;
        }
        var html = data.html;
        var new_obj = JSON.parse(data.obj);

        for (var key in new_obj){
          obj[key] = new_obj[key];
        } 

        if(Object.keys(new_obj).length < OFFER_PER_PAGE){
          $("#current-section-more").hide();
        }

        $('#grid_offers').append(html);
          }
       });

       current_offset += OFFER_PER_PAGE;
       return false;
  });

});

$(function($){

  // Add class to body 
  $('body').addClass('doc-ready')

  // svg polyfill for IE
  svg4everybody(); 

  // Lazy load
  let lazyLoad = $('.lazy').Lazy({
    chainable: false,
    effect: 'fadeIn',
    effectTime: 500,
    visibleOnly: true,
    afterLoad: function(element) {
      element.removeClass('lazy');
    }
  }); 

  $('.horizontal-lazy').Lazy({
    appendScroll: $('div'),
    visibleOnly: true
  });

  $(document).ajaxSuccess(function(event, xhr, settings ) {

    $('.lazy').Lazy({
      chainable: false,
      effect: 'fadeIn',
      effectTime: 500,
      visibleOnly: true,
      afterLoad: function(element) {
        element.removeClass('lazy');
      }
    }); 

  });

  // Hidden menu
  $('.hamburger-btn, .close-menu').on('click', function(e) {
    e.preventDefault();

    $('body').toggleClass('overflow-hidden');
    $('.global-overlay').fadeToggle();
    $('.hidden-menu-wrapper').toggleClass('is-open');
  });

  $('.global-overlay').on('click', function(e) {
    e.preventDefault();

    $('body').removeClass('overflow-hidden');
    $('.global-overlay').fadeOut();
    $('.hidden-menu-wrapper').removeClass('is-open');
    $('.popup').fadeOut();
    $('.not-logged textarea').prop('disabled', false);
  });

  // Profile dropdown
  $('.profile-link').on('click', function(e) {
    e.preventDefault();

    $(this).toggleClass('open').siblings('.profile-options').slideToggle();
    $(this).closest('.hidden-menu-wrapper').find('.profile-options').slideToggle();
  });

  // Menu search
  $('.search, .close-search').on('click', function(e) {
    e.preventDefault();

    $('.main-menu .search-form').fadeToggle();
  });

  // Close popup
  $('body').on('click', '.close-popup', function(e) {
    e.preventDefault();

    $('.global-overlay').fadeOut();
    $('body').removeClass('overflow-hidden');
    $('.not-logged textarea').prop('disabled', false);
    $(this).closest('.popup').fadeOut();
  });

  // Close block
  $('.close-block').on('click', function(e) {
    e.preventDefault();

    let cookieName = $(this).closest('.gray-info').attr('data-cookie');

    $(this).closest('.gray-info').slideUp();

    Cookies.set(cookieName, 'hidden', { expires: 30 })
  });

  if (Cookies.get('recommended-info') === 'hidden'){
    $('.gray-info[data-cookie=recommended-info]').hide();
  } else {
    $('.gray-info[data-cookie=recommended-info]').slideDown();
  }

  if (Cookies.get('how-to-like') === 'hidden'){
    $('.gray-info[data-cookie=how-to-like]').hide();
  } else {
    $('.gray-info[data-cookie=how-to-like]').slideDown();
  }

  // Scroll to active element in horizontal div
  if($('.progress-slider .active').length > 0) {
    let windowWidth = $(window).width();
    let activeOffset = $('.progress-slider .active').offset().left;
    let scrollPosition = activeOffset - windowWidth/2;
    $('.progress-slider').scrollLeft(scrollPosition);
  }

  // GDPR
  $('.trigger-faktorio').on('click', function (e) {
    e.preventDefault();
    
    $('#fingerprint').trigger('click');
  });

  // Anchor - without refresh page
    $(document).on('click', '.dictionary .progress-slider', function () {
      //e.preventDefault();
      $('html, body').animate({
          scrollTop: $(this).offset().top - 120 + 'px'
      }, 500);
  });

});

$.fn.wrapInTag = function(opts) {
  
  var tag = opts.tag || 'strong',
      words = opts.words || [],
      regex = RegExp(words.join('|'), 'gi'),
      replacement = '<'+ tag +'>$&</'+ tag +'>';
  
  return this.html(function() {
    return $(this).text().replace(regex, replacement);
  });
};



$(function($){

  if($('.search-results').length > 0) {
    let searchForm = $('.search-results').closest('.page-content').find('.search-form');
    let searchQuery = searchForm.find('input[type=text]').val().split(' ');

    $('.search-results .description, .search-results .title a').wrapInTag({
      tag: 'strong',
      words: searchQuery
    });
  }

  

});

$(function($){

  // On scroll
  let startPosition = 500;
  let fixedHeader = false;

  if($('.page-header').hasClass('fixed')) {
    fixedHeader = true; // Don't fire sticking of header
  }

  setTimeout(function(){
    onScroll(startPosition);
    $(document).on('scroll', function() {
      onScroll(startPosition);
    });
  }, 10);

  function onScroll(startPosition) {
    let position = $(window).scrollTop();

    // Fixed header

    if(!fixedHeader) {

      if(position < startPosition - 200) {
        $('.page-header').removeClass('hide');
        $('.page-header').removeClass('fixed');
      }

      if(position > startPosition - 200 && position < startPosition - 100) {
        $('.page-header').addClass('hide');
      } 

      if(position > startPosition - 100 && position < startPosition) {
        $('.page-header').addClass('fixed');
      }

      if(position > startPosition) {
        $('.page-header').addClass('fixed');
        $('.page-header').removeClass('hide');
      }

    }

  }

});

$(function($){

  // Tabs
  $('.tabs a').on('click', function(e) {

    if(!$(this).attr('data-tab')) {
      return;
    }

    e.preventDefault();

    let tab = $(this).attr('data-tab');
    let tabsWrapper = $(this).closest('.tabs-wrapper');
    let tabContent = $(`.tab-content[data-tab=${tab}]`);

    $(this).addClass('active').siblings('a').removeClass('active');
    tabContent.addClass('active').siblings('.tab-content').removeClass('active');
    
    if($(this).closest('.tabs').hasClass('.progress-slider')) {
      let windowWidth = $(window).width();
      let activeOffset = $('.progress-slider .active').offset().left;
      let scrollPosition = activeOffset - windowWidth/2;
      $('.progress-slider').scrollLeft(scrollPosition);
    }

    if(tabsWrapper.hasClass('changing-link-tabs')) {
      let pathName = window.location.pathname.split('.');
      let pathNameSplit = pathName[0].split('/');
      pathNameSplit[pathNameSplit.length - 1] = tab;
      let newPathName = pathNameSplit.join('/');

      if (pathName.length > 1 && pathName[1] === 'html') {
        newPathName += '.html';
      }

      if(window.location.pathname !== newPathName) {
        let newurl = window.location.protocol + '//' + window.location.host + newPathName;
        window.history.pushState({ path: newurl }, '', newurl);
      }
    }

  });

  $(window).on('popstate', function(e) {
    location.reload()
  });

});

$(function ($) {
  function updateHeroBackground(currentSlide, isInit = false) {
    var $currentSlide = $('.slider .slick-slide').eq(currentSlide);

    if ($currentSlide.attr('data-special') === 'true') {
      var bgMobile = $currentSlide.data('mobile-image'),
          bgTablet = $currentSlide.data('special-image'),
          bgDesktop = $currentSlide.data('desktop-image'),
          slideLink = $currentSlide.data('link'),
          bgImage = bgMobile,
          topOffset = '0px';

      if (window.innerWidth >= 990) {
        bgImage = bgDesktop;
        topOffset = '117px';
      } else if (window.innerWidth >= 768) {
        bgImage = bgTablet;
        topOffset = '117px';
      } else if (window.innerWidth >= 575) {
        bgImage = bgTablet;
        topOffset = '40px';
      }

      if (!isInit) {
        $('.hero-section').css('opacity', '0');
      }

      setTimeout(function () {
        $('.hero-section').css({
          'background-image': 'url(' + bgImage + ')',
          'background-size': 'cover',
          'background-position': 'center ' + topOffset,
          'background-repeat': 'no-repeat',
          'opacity': '1',
          'transition': isInit ? 'none' : 'opacity 500ms ease-in-out',
          'cursor': 'pointer'
        }).attr('data-clickable', 'true')
          .data('link', slideLink);
      }, isInit ? 0 : 300);

    } else {
      $('.hero-section').css({
        'opacity': '0',
        'cursor': 'default'
      });

      setTimeout(function () {
        $('.hero-section').css({
          'background-image': 'none',
          'opacity': '1'
        }).removeAttr('data-clickable')
          .removeData('link');
      }, isInit ? 0 : 300);
    }
  }

  $('.slider').slick({
    slidesToShow: 1,
    infinite: true,
    arrows: false,
    fade: true,
    dots: true,
    autoplay: true,
    autoplaySpeed: 4000
  });

  setTimeout(function () {
    var currentSlide = $('.slider').slick('slickCurrentSlide');
    updateHeroBackground(currentSlide, true);
  }, 0);

  $('.slider').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
    updateHeroBackground(nextSlide);
  });

  $(window).resize(function () {
    var currentSlide = $('.slider').slick('slickCurrentSlide');
    updateHeroBackground(currentSlide, true);
  });

  $('.slick-dots').on('click', function (event) {
    event.stopPropagation();
  });

  $('.hero-section').on('click', function () {
    if ($(this).attr('data-clickable') === 'true') {
      var link = $(this).data('link');
      if (link) {
        window.open(link, '_blank');
      }
    }
  });
});

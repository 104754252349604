$(function ($) {

  let form = $('.gender-form');
  let chineseGenderChart = {
    18: [1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    19: [0, 1, 0, 1, 1, 0, 0, 1, 0, 0, 1, 1],
    20: [1, 0, 1, 0, 0, 0, 0, 0, 0, 1, 0, 0],
    21: [0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
    22: [1, 0, 0, 1, 0, 1, 1, 0, 1, 1, 1, 1],
    23: [0, 0, 0, 1, 0, 0, 1, 1, 1, 0, 0, 1],
    24: [0, 1, 1, 0, 0, 1, 0, 1, 0, 0, 1, 0],
    25: [1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 0, 0],
    26: [0, 0, 0, 0, 0, 1, 0, 1, 1, 0, 1, 1],
    27: [1, 1, 0, 0, 1, 0, 1, 1, 0, 1, 0, 0],
    28: [0, 0, 0, 1, 1, 0, 1, 0, 1, 1, 0, 1],
    29: [1, 0, 1, 1, 0, 1, 1, 0, 1, 0, 1, 1],
    30: [0, 0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0],
    31: [0, 0, 0, 0, 1, 1, 0, 1, 0, 1, 1, 1],
    32: [0, 1, 1, 0, 1, 0, 0, 1, 0, 0, 1, 0],
    33: [1, 0, 0, 1, 1, 0, 1, 0, 1, 0, 0, 1],
    34: [0, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1, 1],
    35: [0, 1, 0, 1, 0, 1, 0, 1, 0, 0, 1, 0],
    36: [0, 1, 0, 0, 0, 1, 0, 0, 1, 1, 1, 1],
    37: [1, 1, 0, 1, 1, 1, 0, 1, 1, 0, 0, 0],
    38: [0, 0, 1, 1, 0, 1, 1, 0, 1, 1, 0, 1],
    39: [1, 1, 0, 1, 1, 1, 0, 1, 0, 0, 1, 0],
    40: [0, 0, 0, 1, 0, 1, 0, 1, 0, 1, 1, 0],
    41: [1, 1, 0, 1, 0, 0, 1, 1, 0, 1, 0, 1],
    42: [0, 1, 1, 0, 0, 0, 0, 0, 1, 0, 1, 0],
    43: [1, 0, 1, 1, 0, 0, 0, 1, 1, 1, 0, 0],
    44: [0, 1, 1, 1, 0, 1, 0, 0, 1, 0, 1, 0],
    45: [1, 0, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1]
  }

  form.on('submit', function (e) {
    e.preventDefault();

    if ($(this).valid()) {
      
      $('.form-wrapper').hide();
      $('.result-wrapper').addClass('open');

      let age = $(this).find('#age option:selected').val();
      let month = $(this).find('#month option:selected').val();

      if (chineseGenderChart[age][month-1]) {
        $('.gender').text('момиче');
        $('.baby-gender').attr('src', $('.baby-gender').attr('data-src-girl'));
      } else { 
        $('.gender').text('момче');
        $('.baby-gender').attr('src', $('.baby-gender').attr('data-src-boy'));
      }
    }

  });

});

$(function ($) {

  let form = $('.weight-form');

  function calculateBMI(weight, height) {
    let bmi = weight / (height / 100 * height / 100);
    return bmi;
  }

  form.on('submit', function (e) {
    e.preventDefault();

    if($(this).valid()) {
      
      $('.form-wrapper').hide();
      $('.result-wrapper').addClass('open');

      let weightBefore = $('#weight-before').val();
      let weightNow = $('#weight-now').val();
      let height = $('#height').val();
      let minWeight = parseInt(weightBefore);
      let maxWeight = parseInt(weightBefore);

      let bmi = calculateBMI(weightBefore, height);
      let minStep = 0.135;
      let maxStep = 0.185;
      let minBMIStep;
      let maxBMIStep;

      if (bmi < 18.5) {
        minBMIStep = 0.500;
        maxBMIStep = 0.600;
      }
      if (bmi > 18.5 && bmi < 25) {
        minBMIStep = 0.400;
        maxBMIStep = 0.500;
      }
      if (bmi > 25) {
        minBMIStep = 0.300;
        maxBMIStep = 0.400;
      }
      if ($('#twins').is(':checked')) {
        minBMIStep += 0.130;
        maxBMIStep += 0.200;
      }

      let currentWeek = $('#week option:selected').val();
      let weekWeight = [];

      let weightResultsMin = [];
      let weightResultsMax = [];
      let weekLabels = [];
      let pointsRadius = [];

      weightResultsMin[0] = minWeight;
      weightResultsMax[0] = maxWeight;
      weekWeight[0] = minWeight;
      weekLabels[0] = 0;
      pointsRadius[0] = 0;

      $('.weight-gain-table tbody tr').remove();

      for (let i = 0; i < 40; i++) {
        weekLabels[i + 1] = i + 1;
        pointsRadius[i + 1] = 0;
        weekWeight[i + 1] = parseInt(weightBefore);

        if (i < 14) {
          minWeight += minStep;
          maxWeight += maxStep;
        } else {
          minWeight += minBMIStep;
          maxWeight += maxBMIStep;
        }

        weightResultsMin[i + 1] = parseFloat(minWeight.toFixed(1));
        weightResultsMax[i + 1] = parseFloat(maxWeight.toFixed(1));
        let averageWeight = (minWeight + maxWeight ) / 2;

        $('.weight-gain-table tbody').append(`<tr>
          <td>${i + 1}</td>
          <td>${minWeight.toFixed(1)}</td>
          <td>${maxWeight.toFixed(1)}</td>
          <td>${averageWeight.toFixed(1)}</td>
        </tr>`);
      }

      weekWeight[currentWeek] = parseFloat(weightNow);
      pointsRadius[currentWeek] = 8;

      $('.weight-gain-table tbody tr').eq(currentWeek - 1).addClass('current-week')

      if (weekWeight[currentWeek] > weightResultsMax[currentWeek]) {
        $('.your-weight').text('по-високо');
      }
      if (weekWeight[currentWeek] < weightResultsMin[currentWeek]) {
        $('.your-weight').text('по-ниско');
      }
      if (weekWeight[currentWeek] >= weightResultsMin[currentWeek] && weekWeight[currentWeek] <= weightResultsMax[currentWeek]) {
        $('.your-weight').text('нормално');
      }

      $('.current-week-number').text(currentWeek);

      $('.recommended-weight').text(`${weightResultsMin[currentWeek]} - ${weightResultsMax[currentWeek]}кг`)

      let data = {
        labels: weekLabels,
        datasets: [{
            backgroundColor: "#9ba7f6",
            label: 'Минимално тегло',
            data: weightResultsMin,
            borderWidth: 1,
            fill: '1',
            lineTension: 0,
            pointRadius: 0,
            pointHoverRadius: 0
          },
          {
            backgroundColor: "#9ba7f6",
            label: 'Максимално тегло',
            data: weightResultsMax,
            borderWidth: 1,
            fill: '-1',
            lineTension: 0,
            pointRadius: 0,
            pointHoverRadius: 0,

          },
          {
            backgroundColor: "#fc5e2f",
            label: 'Теглото ти',
            data: weekWeight,
            borderWidth: 0,
            fill: 'false',
            pointRadius: pointsRadius,
            pointHoverRadius: pointsRadius,
            showLine: false
          }
        ]
      }

      let ctx = document.getElementById('chart').getContext('2d');

      var myChart = new Chart(ctx, {
        type: 'line',
        data: data,
        options: {
          events: ['click'],
          legend: {
            display: false
          },
          tooltips: {
            mode: 'index'
          },
          scales: {
            yAxes: [{
              scaleLabel: {
                display: true,
                labelString: 'Тегло'
              }
            }],
            xAxes: [{
              scaleLabel: {
                display: true,
                labelString: 'Седмица'
              },
              ticks: {
                autoSkip: true,
                autoSkipPadding: 15,
                maxRotation: 0
              },
              gridLines: {
                color: 'rgba(0, 0, 0, 0)',
              }
            }]
          }
        }
      });
    }

  });

  $('.btn-recalculate').on('click', function(e) {
    e.preventDefault();

    $('.result-wrapper').removeClass('open');
    $('.form-wrapper').show();
    $('html, body').animate({
      scrollTop: $('.form-wrapper').offset().top - $('.page-header').height() - 20
    }, 'slow');
  });

});
